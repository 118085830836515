import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Decoding } from '../../../helpers/CodingHelper';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import ZoomCarousel from './Components/ZoomCarousel/ZoomCarousel';

import f2 from '../Design/img/buck3.png';
import CompanyPropsHelper from '../../../helpers/CompanyPropsHelper';
import RequestDefaultsHelper from '../../../helpers/RequestDefaultsHelper';
import GtagHelper from '../../../helpers/GtagHelper';
import SetThemeColor from "../../../utils/setThemeColor";

const Footer = React.lazy(() => import('./Footer/footer'));
const PageLayout = React.lazy(() => import('./Components/PageLayout/PageLayout'));

require('dotenv').config();
const Carousel = React.lazy(() => import('./Components/carousel.jsx'));
const SignInModal = React.lazy(() => import('../Design/Components/signInModal'));

@inject('EventStore')
@inject('CompanyStore')
@inject('AuthStore')
@observer
class Platforms extends Component {
  state = {
    api: '',
    loading: false,
    companydetail: {},
    videoPlay: false,
    videoMute: true,
  };

  addDefaultSrc = (ev) => {
    ev.target.src = f2;
  };

  async componentDidMount() {
    await this.setState({ loading: true });
    await this.props.AuthStore.setCompanyUrlName(
      this.props?.match?.params?.company_name ? this.props.match.params.company_name : 'igi',
    );
    // setTimeout(async () => {
     await CompanyPropsHelper(this.props?.match?.params?.company_name ? this.props.match.params.company_name : 'igi', true).then(async (data) => {
      await GtagHelper(this.props?.match?.params?.company_name ? this.props.match.params.company_name : 'igi');
      await RequestDefaultsHelper(data.url, data.api_key, this.props?.match?.params?.company_name ? this.props.match.params.company_name : 'igi', data.company_data);
      // if(data.company_data.company.theme_color == 'white'){
      //   this.redirectToEvent();
      // }
      await this.setState({ companydetail: data.company_data });
      await SetThemeColor(data.company_data.company.theme_color)
      await this.props.EventStore.getCarouselEventItems();
      await this.props.EventStore.getLiveEvents();
      this.props.CompanyStore.market_place = data.company_data.company.show_marketplace;
      if(this.props.EventStore.events.length === 1){
        this.redirectToEvent(this.props.EventStore.events[0].id);
        // this.setState({ loading: false });
      }
      else{
        this.setState({ loading: false });
      }
    });
  // }, 2000);

    
    // CompanyPropsHelper(this.props.match.params.company_name, true).then(async (data) => {
    //   RequestDefaultsHelper(
    //     data.url,
    //     data.api_key,
    //     this.props.match.params.company || this.props?.match?.params?.company_name,
    //     data.company_data,
    //   );
    //   console.log(JSON.parse(JSON.stringify(data.company_data)), 'vvvv');
    //   this.setState({ companydetail: data.company_data });
    // });
  }

  redirectToEvent = (event_id) => {
    const { company_url_name } = this.props.AuthStore;
    this.props.history.push(`/platforms/${company_url_name}/events/${event_id}`);
  };

  VideoPlay_button = () => {
    // console.log('hello1');
    var video = document.getElementById('videoplay');
    if (this.state.videoPlay) {
      video.pause();
      this.setState({ videoPlay: false });
      // console.log('hello');
      return;
    }
    video.play();
    this.setState({ videoPlay: true });
  };
  VideoMute_button = () => {
    var video = document.getElementById('videoplay');
    if (this.state.videoMute) {
      video.muted = true;
      this.setState({ videoMute: false });
      return;
    }
    video.muted = false;
    this.setState({ videoMute: true });
  };
  render() {
    SetThemeColor(this.state.companydetail.company?.theme_color)
    const FeaturedVideoUrl = this.state.companydetail?.company?.featured_video?.featured_video?.url;
    let link = this.props.match.params.company_name || sessionStorage.getItem('company_name');
    if (link.includes('igi')) {
      link = null;
    }
    const headerProps = {
      body_color: this.state.companydetail?.company?.body_color,
      btcolor: this.state.companydetail?.company?.button_text_color
        ? this.state.companydetail.company.button_text_color
        : '#00471b',
      bgcolor: this.state.companydetail?.company?.primary_color,
      Logo: this.state.companydetail?.company?.logo?.logo?.url,
      bcolor: this.state.companydetail?.company?.button_color
        ? this.state.companydetail.company.button_color
        : '#00471b',
      drpColor: this.state.companydetail?.company?.primary_color || 'black',
      CheckPortfolio: this.state.companydetail?.company?.show_myportfolio,
      link: link ? `/platforms/${link}` : '/',
      subdomain: this.props.match.params.company_name,
    };
    const footerProps = {
      color: this.state.companydetail?.company?.primary_color || 'black',
      links: this.state.companydetail?.company,
    };

    return (
      <>
        <div className="row no-gutter shadow-lg"></div>
        <PageLayout headerProps={headerProps} loading={this.state.loading} match={this.props.match}>
          {this.props.EventStore.carouseleventitems.length > 0 && (
            <ZoomCarousel
              AuthStore={this.props.AuthStore}
              items={this.props.EventStore.carouseleventitems}
              logo={this.state.companydetail?.company?.background_image?.background_image?.url}
              company_url_name={this.props.AuthStore.company_url_name}
              colors={{
                // body: this.state.companydetail?.company?.body_color,
                secondary: this.state.companydetail?.company?.secondary_color,
                primary_color: this.state.companydetail?.company?.primary_color,
                textColors: this.state.companydetail?.company?.button_text_color,
              }}
            />
          )}
          <div id="root">
            <div style={{}}>
              {/* <img
              src={this.state.companydetail?.company?.background_image?.background_image?.url}
              width="100%"
              height="auto"
              style={{ maxHeight: '400px' }}
            /> */}
              <div className="text-center">
                <h3 className="text-white d-none" style={{ marginTop: '-80px' }}>
                  {this.state.companydetail?.company?.name}
                </h3>
              </div>
            </div>
            <div className="conatiner shadow-lg container-flex platform-header reversebg platform-text">
              <div className={`${this.state.companydetail?.company?.body_color == '#000000' ? '' : 'black-text'}`}>
                <div className="align-items-center gap-5">
                  <div className="filter-img">
                    <img
                      src={this.state.companydetail?.company?.logo?.logo?.url}
                      style={{ width: '100px', paddingBottom: '30px' }}
                      className="filter-img"
                    />
                  </div>
                  <div>
                    <h3 className="d-none">{this.state.companydetail?.company?.name}</h3>
                  </div>
                </div>
                <div
                  className="text-center"
                  dangerouslySetInnerHTML={{ __html: this.state.companydetail?.company?.top_text }}
                />
              </div>
            </div>

            {/* {FeaturedVideoUrl ? (
          <section>
            <div className="container text-center" style={{ maxWidth: '1518px' }}>
              <div className="row justify-content-center">
                <div className="col-md-6 col-sm-12 text-left">
                  <div
                    className={`${
                      this.state.companydetail?.company?.body_color == '#000000' ? 'white-text' : 'black-text'
                    }`}
                  >
                    <div
                      className=" font-class text-left"
                      dangerouslySetInnerHTML={{ __html: this.state.companydetail?.company?.top_text }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col featured_video_adjustment">
                    <div onClick={() => this.VideoMute_button()}>
                      <i
                        className={
                          this.state.videoMute == true
                            ? 'fa fal  fa-volume-up mute_button'
                            : 'fa fal fa-volume-mute mute_button'
                        }
                      ></i>
                    </div>
                    <div onClick={() => this.VideoPlay_button()}>
                      <i className={this.state.videoPlay == false ? 'fa fab fa-play play_button' : ''}></i>
                    </div>
                    <video
                      onClick={() => this.VideoPlay_button()}
                      loop
                      className="video_main_page"
                      src={FeaturedVideoUrl}
                      type="video/mp4"
                      id="videoplay"
                      style={{ cursor: 'pointer', boxShadow: 'rgb(0 0 0 / 59%) 8px 7px 6px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div
            className={`${this.state.companydetail?.company?.body_color == '#000000' ? 'white-text' : 'black-text'}`}
          >
            <div
              className=" font-class text-left"
              dangerouslySetInnerHTML={{ __html: this.state.companydetail?.company?.top_text }}
            />
          </div>
        )} */}

            {/* <div className="row justify-content-center">
          <div className="col-md-6">

          </div>
          <div className="col-md-6">
            <div className="col" style={{ position: "relative" }}>
              <div onClick={() => this.VideoMute_button()}>
                <i className={this.state.videoMute == true ? "fa fal  fa-volume-up mute_button" : "fa fal fa-volume-mute mute_button"}
                ></i>
              </div>
              <div onClick={() => this.VideoPlay_button()}>
                <i className={this.state.videoPlay == false ? "fa fab fa-play play_button" : ""} aria-hidden="true"
                ></i>
              </div>
              <video
                onClick={() => this.VideoPlay_button()}
                loop
                className="video_main_page"
                src={FeaturedVideoUrl}
                type="video/mp4"
                id="videoplay"
                style={{ cursor: "pointer", boxShadow: "rgb(0 0 0 / 59%) 8px 7px 6px" }}
              />
            </div>
          </div>
        </div> */}

            <div className="container-flex text-center" style={{ margin: '5rem 22rem 5rem' }}>
              <h3 className="rootbg">Collection</h3>
            </div>

            <div
              className={
                this.props.EventStore.events.length > 2
                  ? 'eventsContainerConditional'
                  : this.props.EventStore.events.length > 1
                  ? 'eventsContainerConditional eventsContainerConditionalII'
                  : 'eventsContainerConditional eventsContainerConditionalI'
              }
              // style={{ margin: '7rem 0 0 55rem' }}
            >
              {this.props.EventStore.events.map((ev) => (
                <div className="shadow-lg platform-items reversebg" style={{}}>
                  <Link to={`/platforms/${this.props.AuthStore.company_url_name}/events/${ev.id}`}>
                    <div
                      className="shadow p-5 d-flex align-items-center justify-content-center"
                      style={{ height: '75px' }}
                    >
                      <h2 className="">{ev.title}</h2>
                    </div>
                    <div>
                      <img
                        src={ev.photo.photo.url}
                        onError={this.addDefaultSrc}
                        alt={ev.title}
                        style={{ width: '100%', height: '350px', objectFit: 'cover' }}
                      />
                    </div>
                    <div className="p-5 d-flex align-items-center justify-content-center" style={{ height: '75px' }}>
                      <h2 className="">Enter Here</h2>
                    </div>

                    {/* <p style={{ fontSize: '15px' }}>{ev.description} </p> */}
                  </Link>
                </div>
              ))}
            </div>
            {/* <div className={`${this.state.companydetail?.company?.body_color == '#000000' ? 'white-text' : 'black-text'}`}>
          <div
            className=" font-class text-left backend_text"
            dangerouslySetInnerHTML={{ __html: this.state.companydetail?.company?.bottom_text }}
          />
        </div> */}
          </div>
          <Footer />
          <section>
            <div className="row no-gutters text-center copy-rights" style={{ padding: '5rem' }}>
              <h6 className="">
                <i className="fa  fab fa-copyright"></i> {new Date().getFullYear()} I Got It - All Rights Reserved
              </h6>
            </div>
          </section>
        </PageLayout>
      </>
    );
  }
}

export default withRouter(Platforms);
