import React, { useContext, useState, useEffect } from 'react';
import ItemCard from '../ItemCard/MktItemCard';
import ZoomCarouselItemCard from '../ItemCard/ZoomCarouselItemCard';
import { EventItemId } from '../../../../../helpers/ParamsIdHelper';
import { WishlistStoreContext } from '../../../../../store/wishlist.store';
import { notification, Popconfirm, Spin, DatePicker, Select } from "antd";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../../Design/Marketplace.scss';

const ZoomCarousel = ({ colors, items, logo, company_url_name, AuthStore }) => {
  const [loading, setLoading] = useState(false);
  const WishlistStore = useContext(WishlistStoreContext);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    (async () => {

      setLoading(true);
      await WishlistStore.getWishlist();
      setLoading(false);
    })();

  }, [WishlistStore]);

  const addToWishlist = (itemId, added) => async () => {
    console.log(itemId);
    console.log(added);
    setLoading(true);
    const data = new FormData();
    data.append('event_item_id', itemId);

    if (added) {
      await WishlistStore.deleteWishlist({ id: itemId });
    } else {
      await WishlistStore.addWishlist(data);
    }
    await WishlistStore.getWishlist();
    setLoading(false);
  };
  const cardItem =
    items?.map((ev, index) => {
      let added = false;
      if (WishlistStore.wishlist.find((wishlistItem) => wishlistItem.id === ev.id)) {
        added = true;
      }
      let PriceType = ev.price;
      if (ev.item_type == 'Bid Only') {
        PriceType = ev?.price;
        if (ev.current_bid?.price) {
          PriceType = ev?.current_bid?.price;
        }
      }
      const item = {
        id: `${ev.id}`,
        AuthStore,
        image: ev.item.is_digital ? ev.item?.digital_item?.photo ?? '' : ev.item.photos[0]?.photo?.photo?.url ?? '',
        deadline: new Date(ev.closes_at * 1000),
        altText: `${ev.item.title}`,
        video: ev.item.is_digital ? ev.item?.digital_item?.preview_asset ?? '' : ev.item?.videos?.[0]?.video_url ?? '',
        thumb: ev.item.photos[0]?.photo?.photo.thumb ?? '',
        previewAsset: ev.item?.digital_item?.preview_asset,
        description: `${ev.description}`,
        itemType: ev.item_type,
        price: `${PriceType != null ? +PriceType : 'Free'}`,
        title: ev.item.title ?? '',
        colors: colors,
        itemDetailUrl: `/platforms/${company_url_name}/items/${EventItemId(ev.item_type)}/${ev.id}`,
        masterQuantity: ev.item.is_digital ? ev.item.digital_item?.master_quantity : 0,
        skuNumber: ev.item.is_digital ? ev.item.asset_index : 0,
        Quantity: ev.quantity ? ev.quantity : 0,
        Membership: ev.item?.digital_item?.is_membership,
        channel_name: ev.channel_name,
        company_url_name: company_url_name,
      };
      return (
        <ZoomCarouselItemCard
         added={added}
         addToWishlist={addToWishlist(item.id, added)}
         index={index}
          {...item}
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            maxWidth: '60rem',
            margin: 'auto',
            padding: '1rem',
            backgroundColor: '#2c2c2c',
            width: '80rem',
            maxWidth: '80rem',
            minHeight: '25rem',
            height: '25em',
            // alignItems: 'center',
            borderRadius: '2px',
          }}
        />
      );
    }) || [];

  return (
    <Spin spinning={loading}>
    <div
      className=" carouselContainer reversebg"
      style={{
        backgroundImage: `url(${logo})`,
        backgroundColor: `${colors.secondary}`,
        minHeight: '400px',
        height: '400px',
      }}
    >
      <Carousel
        removeArrowOnDeviceType={['mobile', 'tablet']}
        responsive={responsive}
        slidesToSlide={1}
        autoPlay
        autoPlaySpeed={6000}
        infinite
        itemClass="d-flex"
      >
        {cardItem}
      </Carousel>
    </div>
    </Spin >
  );
};

export default ZoomCarousel;
