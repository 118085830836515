import { observable, action } from "mobx";
import { post, get } from "../services/ajax.service";


class StripeStore {
    @observable error = "";
    @observable StripeVendorAndRequire = {};
    @observable errortoggle = false;
    @observable credit_balance = 0 ;
    @action getStripe = async (data) => {
        console.log(this.error);
        try {
            const response = await post(`/stripes/add_card.json`, {stripe_token:data});
            this.error = ''
            return response;
        }
        catch (err) {
            this.error = err.response?.data?.errors[0];
            console.log(this.error)
        }
    };

    @action getSetupStripeIntent = async (data) => {
        console.log(this.error);
        try {
            const response = await post(`/stripes/setup_stripe_intent.json`);
            this.error = ''
             return response.data;
        }
        catch (err) {
            this.error = err.response.data.errors[0];
            console.log(this.error)
        }
    };

    @action getCreditBalance = async (options = {}) => {
        this.creditbalance = {};
        try {
            let response = await get(
                `/stripes/credit_balance.json`, options);

                console.log(response.data)
            this.creditbalance = response.data.credit_balance;
            this.errortoggle = false;
        } catch (err) {
            this.error=err.response.data;
            this.errortoggle = true;
        }
    }

    @action getVendorandStripe = async () => {
        this.StripeVendorAndRequire = {};
        try {

            const response = await get("/users/get_vendor_and_stripe_requirements.json");
            this.StripeVendorAndRequire = response.data;
        }
        catch (err) {
            this.errors=err.responce;
            console.log(err.responce);
        }
    };

    @action setDefault = async (data) => {
        try {

           let response= await post(`/stripes/default_card.json`, data);

           this.error=response.data.message;

        }
        catch (err) {
            console.log(err, "error");
            this.error=err.response.data.errors[0];
        }
    };
    @action deleteCard = async (data) => {
        try {

            let response= await post(`/stripes/delete_card.json`, data);

            this.error=response.data.message;
        }
        catch (err) {
            this.error=err.response.data.errors[0];
            console.log(err, "error");
        }
    };

}

export default StripeStore;

