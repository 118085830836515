import { observable, action } from "mobx";
import { post,get } from "../services/ajax.service";
import { createContext } from 'react';

class UserStore {

    @observable action = false;
    @observable setting = {};
    @observable leaderboard = {};
    @observable loyaltyconfig = {};
    @observable shippingaddress = {};
    @observable error = "";
    @observable Serror = {};
    @observable mybids = [];
    @observable userstotalpoints = [];
    @observable cards = [];
    @observable marketplace_owned_items = [];
    @observable nofticationMessage = "";
    @observable credit_cards_count = '';
    @observable postalcodeinfo = {};


    @action setAction = action => {
        this.action = action;
    };
    @action setMessage = message => {
        this.nofticationMessage = message;
    };
    @action getSetting = async () => {
        this.setting={};
        try {

            const response = await get("/settings.json");
            this.setting = response.data.company;
        }
        catch (err) {
            this.errors=err.responce;
        }
    };
    @action getLoyaltyConfig = async () => {
        this.loyaltyconfig={};
        try {

            const response = await get("/settings/get_loyalty_config.json");
            this.loyaltyconfig = response.data.loyalty_config;
        }
        catch (err) {
            this.errors=err.responce;
        }
    };
    @action getCreditCards = async () => {
        this.cards=[];
        try {

            const response = await get("/users/all_credit_cards.json");
            this.cards = response.data.credit_cards;
            this.credit_cards_count = response.data?.credit_cards?.length;
        }
        catch (err) {
            this.errors=err.responce;
            console.log(err.responce);
        }
    };
    @action setShippingAddress = async (data) => {
        this.shippingaddress={};
        this.error = "";
        this.Serror={}; // TODO: what is this for?
        try {
            const response = await post("/users/shipping_address.json",data);
            this.shippingaddress = response.data;
            this.Serror=response.data;
        }
        catch (err) {
            if (err.response.data.error) {
                this.error = err.response.data.error;
                return err;
              }
              if (err.response.data.errors[0]) {
                this.error = err.response.data.errors[0];
                return err;
              }
        }
    };

    @action setLeaderBoard = async (data) => {
        this.leaderboard={};
        this.error = "";
        this.Serror={}; // TODO: what is this for?
        try {
            const response = await get(`/users/leaderboard.json?api_key=${data}`);
            this.leaderboard = response.data;
            this.Serror=response.data;
        }
        catch (err) {
            if (err.response.data.error) {
                this.error = err.response.data.error;
                return err;
              }

        }
    };
    
    @action getMyBids = async () => {
        this.mybids=[];
        try {

            const response = await get("/users/user_bid_event_items.json");
            this.mybids = response.data.users;
        }
        catch (err) {
            this.errors=err.responce;
            console.log(err.responce);
        }
    };

    @action getUsersTotalPoints = async () => {
        try {

            const response = await get("/users/users_total_points.json");
            this.userstotalpoints = response.data.loyalty_points[0];
        }
        catch (err) {
            this.errors=err.responce;
            console.log(err.responce);
        }
    };

    @action getMarketPlaceOwnedItems = async () => {
        this.marketplace_owned_items=[];
        try {

            const response = await get("/users/user_marketplace_owned_items.json");
            this.marketplace_owned_items = response.data.event_items;
        }
        catch (err) {
            this.errors=err.responce;
        }
    };



    @action getPostalCodeInfo = async (data) => {
        this.postalcodeinfo =null;
        this.errors= {};
        try {
            const response = await get(`/users/detail_by_postal_code.json?postal_code=${data.postal_code}`, data);
            this.postalcodeinfo = response.data.data;
        }
        catch (err) {         
          this.errors=err.response.data;

        //   console.log(JSON.stringify(this.errors), "ERROR");
        //     console.log(err);
        //     return(err);
        }
    };
}
export default UserStore;
export const UserStoreContext = createContext(new UserStore())